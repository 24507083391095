<template>
    <div
        class="pa-4 d-flex justify-space-between"
    >
        <v-btn
            color="primary"
            dark
            depressed
            @click="currencybeacon()"
        >
            currencybeacon
        </v-btn>

        <v-btn
            color="primary"
            dark
            depressed
            @click="coinapi()"
        >
            coinapi
        </v-btn>
    </div>
</template>

<script>
export default {
    data: () => ({
        
    }),

    methods: {
        // # currencybeacon
        async currencybeacon(){
            console.log(":")
            // # 빗썸
             try {
                // 모든 암호화폐 목록을 가져옵니다.
                const tickerResponse = await this.$http.get('https://api.bithumb.com/public/ticker/ALL_KRW');
                const data = tickerResponse.data.data;
                
                // 각 암호화폐의 이름과 가격을 출력합니다.
                for (const [key, value] of Object.entries(data)) {
                    if (key !== 'date') { // 'date'는 제외
                        console.log(`Market: ${key}_KRW, Price: ${value.closing_price}`);
                    }
                }
            } catch (error) {
                console.error('Error fetching KRW pairs with prices:', error);
            }

            return

            // # 업비트
            try {
                // 모든 시장 정보를 가져옵니다.
                const marketResponse = await this.$http.get('https://api.upbit.com/v1/market/all');
                const markets = marketResponse.data;
                
                console.log(markets)

                // KRW로 끝나는 시장만 필터링합니다.
                const krwPairs = markets.filter(market => market.market.startsWith('KRW'));
                
                console.log(krwPairs)

                // KRW 페어의 현재가를 가져옵니다.
                const marketCodes = krwPairs.map(pair => pair.market).join(',');
                const tickerResponse = await this.$http.get('https://api.upbit.com/v1/ticker', {
                    params: { markets: marketCodes }
                });
                const tickers = tickerResponse.data;
                
                // 결과를 출력합니다.
                tickers.forEach(ticker => {
                    const marketInfo = krwPairs.find(pair => pair.market === ticker.market);
                    console.log(`Market: ${ticker.market}, Korean Name: ${marketInfo.korean_name}, Price: ${ticker.trade_price}`);
                });
            } catch (error) {
                console.error('Error fetching KRW pairs with prices:', error);
            }

            // # 바이낸스
            // try {
            //     const response = await this.$http.get('https://api.binance.com/api/v3/ticker/price');
            //     const rates = response.data;
                
            //     const krwRates = rates.filter(rate => rate.symbol.includes('KRW'));
                
            //     krwRates.forEach(rate => {
            //         console.log(`Symbol: ${rate.symbol}, Price: ${rate.price}`);
            //     });
            // } catch (error) {
            //     console.error('Error fetching KRW exchange rates:', error);
            // }

            return

            // API 키
            const api_key = "E3ZFIsPHbNzUt7s9vgBBGlqbAvq73Zpn"
            
            // # 최근 환율 (155개)
            this.$http.get("/currencybeacon/latest", {
                params: {
                    api_key: api_key,
                    base: "KRW",
                }
            })
            .then((res) => {
                console.log(res.data.rates)
            })

            // # historical (과거 특정 날짜 환율) (전날용?)
            // this.$http.get("/currencybeacon/historical", {
            //     params: {
            //         api_key: api_key,
            //         base: "KRW",
            //         date: "2020-10-30",
            //         symbols: "USD,JPY,EUR",
            //     }
            // }).then((res) => {
            //     console.log(res.data)
            // })

            // # timeseries (유료) (불필요)
            // /currencybeacon/currencies

            // # currencies (각 화폐 자세한 정보)
            // this.$http.get("/currencybeacon/currencies", {
            //     params: {
            //         api_key: api_key,
            //         type: "crypto", // "fiat" or "crypto" 인데 둘다 응답값이 똑같음 (일반 화폐만 나옴)
            //     }
            // }).then((res) => {
            //     console.log(res.data)
            // })

            // # convert (불필요)
            // this.$http.get("/currencybeacon/convert", {
            //     params: {
            //         api_key: api_key,
            //         from: "KRW",
            //         to: "USD",
            //         amount: "10000",
            //     }
            // }).then((res) => {
            //     console.log(res.data)
            // })
        },

        // # CoinAPI
        coinapi(){
            this.$http.get("/coinapi/exchangerate/KRW", {
                params: {
                    invert: true,
                },

                headers: {
                    'Accept': 'text/plain', 
                    'X-CoinAPI-Key': "FC9A357E-EBFE-4310-9D35-BFF1F2D90F89"
                }
            }).then((res1) => {
                console.log(res1.data)
            })
        }
    }
}

</script>