const currencyToCountryCode = {
    // 기존 통화
    'USD': 'us', 'EUR': 'eu', 'JPY': 'jp', 'CNY': 'cn', 'GBP': 'gb',
    'AUD': 'au', 'CAD': 'ca', 'NZD': 'nz', 'THB': 'th', 'VND': 'vn',
    'HKD': 'hk', 'TWD': 'tw', 'CHF': 'ch', 'SEK': 'se', 'INR': 'in',
    'RUB': 'ru', 'BRL': 'br', 'MXN': 'mx', 'TRY': 'tr', 'NOK': 'no',
    'ILS': 'il', 'MYR': 'my', 'HUF': 'hu', 'PLN': 'pl',

    // 추가 통화
    'AED': 'ae', 'AFN': 'af', 'ALL': 'al', 'AMD': 'am', 'ANG': 'an',
    'AOA': 'ao', 'ARS': 'ar', 'AWG': 'aw', 'AZN': 'az', 'BAM': 'ba',
    'BBD': 'bb', 'BDT': 'bd', 'BGN': 'bg', 'BHD': 'bh', 'BIF': 'bi',
    'BMD': 'bm', 'BND': 'bn', 'BOB': 'bo', 'BSD': 'bs', 'BTN': 'bt',
    'BWP': 'bw', 'BYN': 'by', 'BZD': 'bz', 'CDF': 'cd', 'CLF': 'cl',
    'CLP': 'cl', 'COP': 'co', 'CRC': 'cr', 'CUC': 'cu', 'CUP': 'cu',
    'CVE': 'cv', 'CZK': 'cz', 'DJF': 'dj', 'DKK': 'dk', 'DOP': 'do',
    'DZD': 'dz', 'EGP': 'eg', 'ERN': 'er', 'ETB': 'et', 'FJD': 'fj',
    'FKP': 'fk', 'GEL': 'ge', 'GHS': 'gh', 'GIP': 'gi', 'GMD': 'gm',
    'GNF': 'gn', 'GTQ': 'gt', 'GYD': 'gy', 'HNL': 'hn', 'HRK': 'hr',
    'HTG': 'ht', 'IDR': 'id', 'IQD': 'iq', 'IRR': 'ir', 'ISK': 'is',
    'JMD': 'jm', 'JOD': 'jo', 'KES': 'ke', 'KGS': 'kg', 'KHR': 'kh',
    'KMF': 'km', 'KPW': 'kp', 'KRW': 'kr', 'KWD': 'kw', 'KYD': 'ky',
    'KZT': 'kz', 'LAK': 'la', 'LBP': 'lb', 'LKR': 'lk', 'LRD': 'lr',
    'LSL': 'ls', 'LYD': 'ly', 'MAD': 'ma', 'MDL': 'md', 'MGA': 'mg',
    'MKD': 'mk', 'MMK': 'mm', 'MNT': 'mn', 'MOP': 'mo', 'MRO': 'mr',
    'MUR': 'mu', 'MVR': 'mv', 'MWK': 'mw', 'MZN': 'mz', 'NAD': 'na',
    'NGN': 'ng', 'NIO': 'ni', 'NPR': 'np', 'OMR': 'om', 'PAB': 'pa',
    'PEN': 'pe', 'PGK': 'pg', 'PHP': 'ph', 'PKR': 'pk', 'PYG': 'py',
    'QAR': 'qa', 'RON': 'ro', 'RSD': 'rs', 'RWF': 'rw', 'SAR': 'sa',
    'SBD': 'sb', 'SCR': 'sc', 'SDG': 'sd', 'SGD': 'sg', 'SHP': 'sh',
    'SLL': 'sl', 'SOS': 'so', 'SRD': 'sr', 'STD': 'st', 'SVC': 'sv',
    'SYP': 'sy', 'SZL': 'sz', 'TJS': 'tj', 'TMT': 'tm', 'TND': 'tn',
    'TOP': 'to', 'TTD': 'tt', 'TZS': 'tz', 'UAH': 'ua', 'UGX': 'ug',
    'UYU': 'uy', 'UZS': 'uz', 'VEF': 've', 'VUV': 'vu', 'WST': 'ws',
    'XAF': 'cf', 'XCD': 'ag', 'XOF': 'bj', 'XPF': 'pf', 'YER': 'ye',
    'ZAR': 'za', 'ZMW': 'zm', 'ZWL': 'zw',

    'SSP': 'ss', // 남수단
    'VES': 've' // 베네수엘라
}

/**
 * 3글자 통화 코드를 2글자 국가 코드로 변환
 * @param {string} currencyCode - 3글자 통화 코드 (예: USD, EUR)
 * @returns {string} 2글자 국가 코드 (예: us, eu) 또는 찾지 못한 경우 null
 */
export const convertCurrencyToCountryCode = (currencyCode) => {
    return currencyToCountryCode[currencyCode] || null
}