<template>
    <div>
        <!-- 제목 -->
        <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">대시보드</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet
            outlined
            class="d-flex ma-2 pa-4 pb-10"
        >
            <v-sheet
                class="pa-2 ma-4 rounded-10"
                outlined
            >
                <canvas
                    width="340"
                    height="300"
                    ref="kakao_marketing"
                ></canvas>
            </v-sheet>
            <v-sheet
                class="pa-2 ma-4 rounded-10"
                outlined
            >
                <canvas
                    width="340"
                    height="300"
                    ref="sms_marketing"
                ></canvas>
            </v-sheet>
            <v-sheet
                class="pa-2 ma-4 rounded-10"
                outlined
            >
                <canvas
                    width="340"
                    height="300"
                    ref="push_marketing"
                ></canvas>
            </v-sheet>
        </v-sheet>
    </div>
</template>
<script>
export default {
    mounted() {
        this.load()
    },

    methods: {
        load(){
            this.$http.post("/api/user/select/total")
            .then((res) => {
                console.log(res)

                let user_total = res.data[0].user_total
                let kakao_total = res.data[0].kakao_total
                let sms_total = res.data[0].sms_total
                let app_total = res.data[0].app_total


                this.renderChart("카카오톡 마케팅 동의", 'kakao_marketing', [kakao_total], [user_total - kakao_total])
                this.renderChart("SMS 마케팅 동의", 'sms_marketing', [sms_total], [user_total - sms_total])
                this.renderChart("앱 PUSH 마케팅 동의", 'push_marketing', [app_total], [user_total - app_total])
            })
        },

        renderChart(title, chartRef, data1, data2) {
            const ctx = this.$refs[chartRef].getContext('2d')
            
            new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: [title],
                    datasets: [
                        {
                            label: '동의',
                            data: data1,
                            backgroundColor: 'rgba(75, 192, 192, 0.6)',
                        },
                        {
                            label: '거부', 
                            data: data2,
                            backgroundColor: 'rgba(255, 99, 132, 0.6)',
                        }
                    ]
                },
                options: {
                    responsive: true,
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            })
        }
    }
}
</script>